import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Loader } from '../ui-components';
import Layout from './Layout';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 80px)',
  },
}));

function ModuleLoader() {
  const classes = useStyles();

  return (
    <Layout maxWidth="sm" container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box className={classes.wrap}>
            <Loader />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default ModuleLoader;
