import React from 'react';
import { arrayOf, node, oneOfType } from 'prop-types';
import { IntlProvider } from 'react-intl';
import { useGlobalStore } from './redux/GlobalStore';
import { CODES } from '../utils/language';
import EN_UK from '../../i18n/en-UK';
import PT_BR from '../../i18n/pt-BR';
import RU_RU from '../../i18n/ru-RU';
import UK_UA from '../../i18n/uk-UA';

const intlMessages = {
  [CODES.EN_UK]: EN_UK,
  [CODES.RU_RU]: RU_RU,
  [CODES.PT_BR]: PT_BR,
  [CODES.UK_UA]: UK_UA,
};

const LocaleProvider = ({ children }) => {
  const { locale } = useGlobalStore();
  return (
    <IntlProvider locale={locale} messages={intlMessages[locale]}>
      {children}
    </IntlProvider>
  );
};

LocaleProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export default LocaleProvider;
