export const CODES = {
  EN_UK: 'en-UK',
  RU_RU: 'ru-RU',
  PT_BR: 'pt-BR',
  UK_UA: 'uk-UA',
};

export const LANGUAGES = {
  [CODES.EN_UK]: 'English',
  [CODES.RU_RU]: 'Русский',
  [CODES.PT_BR]: 'Português',
  [CODES.UK_UA]: 'Українська',
};
