import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Typography } from '../../../ui-components';
import HelpStep from './HelpStep';

function HelpContentNew() {
  const intl = useIntl();
  return (
    <>
      <HelpStep
        step="1"
        title={intl.formatMessage({
          id: 'helpContent.findTheIncompleteUpload',
        })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.uploadsThatHaveBeenPausedOrInterrupted" />
        </Typography>

        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.onceFoundClickOnTheUpload" />
        </Typography>
      </HelpStep>

      <HelpStep
        step="2"
        title={intl.formatMessage({
          id: 'helpContent.reselectTheFilesToBeUploaded',
        })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.theFilesMustBeReselectedBeforeUploadingCanRestart" />
        </Typography>

        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.whenSelectedTheAppWillVerifyTheFilesSelected" />
        </Typography>

        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.ifFilesHaveBeenAddedOrRemoved" />
        </Typography>
      </HelpStep>

      <HelpStep
        step="3"
        title={intl.formatMessage({ id: 'helpContent.restartTheUpload' })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.withTheCorrectFolderSelected" />
        </Typography>
      </HelpStep>
    </>
  );
}
export default HelpContentNew;
