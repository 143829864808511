import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  menu: {
    display: 'inline-block',
    listStyle: 'none',
    margin: theme.spacing(0, 4, 0, 0),
    padding: 0,

    '& li': {
      display: 'inline-block',

      '& a': {
        backgroundColor: ({ id }) =>
          id ? 'transparent' : 'rgba(0, 165, 207, 0.04)',
      },
    },
  },
}));

function Navigation({ id, children }) {
  const classes = useStyles({ id });

  return (
    <ul className={classes.menu} data-testid="header-menu">
      {children.map(child => (
        <li>{child}</li>
      ))}
    </ul>
  );
}

Navigation.propTypes = {
  id: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

Navigation.defaultProps = {
  id: null,
};

export default Navigation;
