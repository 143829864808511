import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Dialog,
  IconButton,
  Logo,
  Typography,
} from '../../../ui-components';
import Navigation from './Navigation';
import Help from '../Help/Help';
import config from '../../../config';

const headerHeight = '80px';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: headerHeight,
    height: headerHeight,
    backgroundColor: '#fff',
    boxShadow: theme.shadows[1],

    '& h1': {
      marginLeft: theme.spacing(4),
      lineHeight: headerHeight,
      fontSize: 27,
    },
  },
  logo: {
    verticalAlign: 'middle',
    margin: theme.spacing(0, 1, 1, 0),
  },
}));

export default function Header() {
  const [helpVisible, setHelpVisible] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  const classes = useStyles();

  const handleToggleHelp = () => {
    setHelpVisible(visible => !visible);
  };

  const handleLogout = () => {
    history.push('/logout');
  };

  return (
    <div className={classes.header} data-testid="header">
      <Typography variant="h1" component="h1">
        <Logo size={36} className={classes.logo} />
        <FormattedMessage id="header.appName" />
      </Typography>

      <Navigation id={id}>
        <IconButton
          color="primary"
          testid="button-help"
          onClick={handleToggleHelp}
        >
          <HelpIcon />
        </IconButton>

        <Button
          to="/upload"
          variant="text"
          color="primary"
          testid="button-home"
        >
          <FormattedMessage id="header.home" />
        </Button>
        <Button
          href={`${config[process.env.API_ENV].webappUploadUrl}/legacy`}
          variant="text"
          color="primary"
          testid="button-legacy-uploader"
        >
          <FormattedMessage
            id="header.legacyUploader"
            defaultMessage="Legacy Uploader"
          />
        </Button>

        <Button
          type="button"
          variant="text"
          color="primary"
          testid="button-logout"
          onClick={handleLogout}
        >
          <FormattedMessage id="header.logout" />
        </Button>
      </Navigation>

      <Dialog
        title="Help"
        open={helpVisible}
        onClose={handleToggleHelp}
        testid="dialog-help"
      >
        <Help />
      </Dialog>
    </div>
  );
}
