import get from 'lodash/get';
import * as actions from './actions';

const initialUploadState = {
  isFetching: false,
  partialUpload: null,
  manifest: null,
  progress: null,
  isFilesSelected: false,
  isProcessingComplete: false,
  totalFiles: 0,
  totalFilesSize: 0,
  uploadedFileSize: 0,
  compressionData: [],
  uploadData: [],
  uploadSpeed: 0,
  filesUploadedCount: 0,
  errors: [],
  verifyError: null,
};

const uploadReducer = (state, action) => {
  switch (action.type) {
    case actions.FETCHING_UPLOAD:
      return {
        ...state,
        isFetching: true,
      };

    case actions.FETCHING_UPLOAD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        partialUpload: get(action, 'payload.partialUpload', null),
        totalFiles: get(action, 'payload.partialUpload.totalFiles', null),
        manifest: get(action, 'payload.manifest', null),
        progress: get(action, 'payload.progress', null),
        filesUploadedCount: get(action, 'payload.progress', []).length,
      };

    case actions.FETCHING_UPLOAD_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    case actions.FILES_SELECTED:
      return {
        ...state,
        isFilesSelected: true,
        totalFiles: action.payload,
      };

    case actions.FILES_PROCESSED:
      return {
        ...state,
        manifest: action.payload.manifest,
        isProcessingComplete: true,
      };

    case actions.FILES_VERIFIED:
      return {
        ...state,
        isFilesSelected: true,
        isProcessingComplete: true,
        totalFiles: action.payload,
      };

    case actions.VERIFY_ERROR:
      return {
        ...state,
        isFilesSelected: true,
        isProcessingComplete: true,
        verifyError: action.payload,
      };

    case actions.SET_TOTAL_FILE_SIZE:
      return { ...state, totalFilesSize: action.payload };

    case actions.UPLOAD_COMPLETE:
      return { ...state, uploadedFileSize: action.payload };

    case actions.UPDATE_COMPRESSION_DATA:
      return {
        ...state,
        compressionData: state.compressionData.concat(action.payload),
      };

    case actions.UPDATE_UPLOAD_DATA:
      return {
        ...state,
        uploadData: state.uploadData.concat(action.payload.uploadData),
        uploadSpeed: action.payload.uploadSpeed,
        filesUploadedCount: state.filesUploadedCount + 1,
      };

    case actions.SET_UPLOAD_ERRORS:
      return { ...state, errors: action.payload };

    case actions.CLEAR_UPLOAD_ERRORS:
      return { ...state, errors: [] };

    case actions.CLEAR_PARTIAL:
      return {
        ...state,
        isFilesSelected: false,
        isProcessingComplete: false,
        verifyError: null,
      };

    case actions.CLEAR:
    case actions.LOCATION_CHANGE:
      return initialUploadState;

    default:
      return state;
  }
};

export { initialUploadState, uploadReducer };
