import get from 'lodash/get';
import pjson from '../../package.json';

const commpressionLib = 'pako';
const commpressionLibVersion = get(pjson, `dependencies.${commpressionLib}`);

if (!commpressionLibVersion) {
  // eslint-disable-next-line no-console
  console.warn(
    'Please update commpressionLib and commpressionLibVersion in config/common if changed',
  );
}

export default {
  appVersion: pjson.version,
  commpressionLib: `${commpressionLib} : ${commpressionLibVersion}`,
  excludedIfInPath: ['/.thumb/'],
};
