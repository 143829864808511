import get from 'lodash/get';
import * as actions from './actions';

const initialMapState = {
  isMapLoading: false,
  points: null,
  fields: null,
  activeField: null,
};

const mapReducer = (state, action) => {
  switch (action.type) {
    case actions.FETCHING_UPLOAD_SUCCESS:
      return {
        ...state,
        points: get(action, 'payload.points', null),
      };

    case actions.FILES_PROCESSED:
      return {
        ...state,
        points: action.payload.points,
      };

    case actions.FETCH_FIELDS:
      return {
        ...state,
        isMapLoading: true,
      };

    case actions.FETCH_FIELDS_SUCCESS:
      return {
        ...state,
        isMapLoading: false,
        fields: action.payload,
      };

    case actions.FETCH_FIELDS_FAIL:
      return {
        ...state,
        isMapLoading: false,
      };

    case actions.SET_ACTIVE_FIELD:
      return {
        ...state,
        activeField: action.payload,
      };

    case actions.LOCATION_CHANGE:
    case actions.CLEAR:
      return initialMapState;

    default:
      return state;
  }
};

export { initialMapState, mapReducer };
