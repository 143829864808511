import authentication from './authentication.json'
import header from './header.json'
import settings from './settings.json'
import upload from './upload.json'
import uploads from './uploads.json'
import helpContent from './helpContent.json'
import reports from './reports.json'

const EN_UK = {...authentication, ...header, ...settings, ...upload, ...uploads, ...helpContent, ...reports}

export default EN_UK
