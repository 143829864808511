import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  LoginRedirect,
  LogoutRedirect,
} from 'hummingbird-auth-lib/lib-esm/components';
import NotFound from './NotFound';
import PrivateRoute from './PrivateRoute';
import ModuleLoader from './ModuleLoader';

const Uploads = lazy(() =>
  import(/*  webpackChunkName: 'Upload' */ '../modules/Upload/UploadRouter'),
);
const UploadTester = lazy(() =>
  import(
    /*  webpackChunkName: 'UploadTester' */ '../modules/Upload/scenes/UploadTester'
  ),
);
const Report = lazy(() =>
  import(/*  webpackChunkName: 'Report' */ '../modules/Report/scenes/Report'),
);

function Routes() {
  return (
    <Suspense fallback={<ModuleLoader />}>
      <Switch>
        <PrivateRoute path="/upload">
          <Uploads />
        </PrivateRoute>
        <PrivateRoute exact path="/report/:id">
          <Report />
        </PrivateRoute>
        <PrivateRoute path="/test">
          <UploadTester />
        </PrivateRoute>
        <Route exact path="/logout">
          <LogoutRedirect />
        </Route>
        <Route path="/login">
          <LoginRedirect />
        </Route>
        <Route path="/">
          <Redirect to="/upload" />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
}

export default Routes;
