import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { QueryClientProvider } from 'react-query';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { UserProvider } from 'hummingbird-auth-lib/lib-esm/services';
import { GlobalProvider } from './redux/GlobalStore';
import theme from './theme';
import RouteChange from './RouteChange';
import Flash from '../modules/Upload/components/Flash/FlashContainer';
import Routes from './Routes';
import SentryScope from './SentryScope';
import LocaleProvider from './LocaleProvider';

import { client } from '../gqlClient';
import queryClient from './queryClient';
import '../assets/styles/leaflet.css';

function App() {
  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <GlobalProvider>
          <LocaleProvider>
            <UserProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                  <RouteChange>
                    <Routes />
                    <Flash />
                  </RouteChange>
                </BrowserRouter>
                <SentryScope />
              </ThemeProvider>
            </UserProvider>
          </LocaleProvider>
        </GlobalProvider>
      </QueryClientProvider>
    </ApolloProvider>
  );
}

export default App;
