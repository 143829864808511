import React, { useReducer } from 'react';
import PropTypes, { arrayOf, node } from 'prop-types';
import useRootReducer from 'use-root-reducer';

import { initialLocaleState, localeReducer } from './localeReducer';
import {
  initialFlashState,
  flashReducer,
} from '../../modules/Upload/redux/flashReducer';
import {
  initialMapState,
  mapReducer,
} from '../../modules/Upload/redux/mapReducer';
import {
  initialProgressState,
  progressReducer,
} from '../../modules/Upload/redux/progressReducer';
import {
  initialSettingsState,
  settingsReducer,
} from '../../modules/Upload/redux/settingsReducer';
import {
  initialUploadState,
  uploadReducer,
} from '../../modules/Upload/redux/uploadReducer';

const GlobalStateContext = React.createContext();
const GlobalDispatchContext = React.createContext();

function GlobalProvider({ children, ...props }) {
  const [state, dispatch] = useRootReducer({
    locale: useReducer(localeReducer, initialLocaleState),
    flash: useReducer(flashReducer, initialFlashState),
    map: useReducer(mapReducer, initialMapState),
    progress: useReducer(progressReducer, initialProgressState),
    settings: useReducer(settingsReducer, initialSettingsState),
    upload: useReducer(uploadReducer, initialUploadState),
  });

  return (
    // Spread props used in tests for overriding store value
    // eslint-disable-next-line react/jsx-props-no-spreading
    <GlobalStateContext.Provider value={state} {...props}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
}

GlobalProvider.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
};

function useGlobalState() {
  const context = React.useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
}

function useGlobalDispatch() {
  const context = React.useContext(GlobalDispatchContext);
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider');
  }
  return context;
}

function useGlobalStore() {
  return {
    ...useGlobalState(),
    dispatch: useGlobalDispatch(),
  };
}

export { GlobalProvider, useGlobalStore };
