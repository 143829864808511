import get from 'lodash/get';
import {
  CONNECTIONS_DEFAULT,
  COMPRESSION_DEFAULT,
  SIMPLIFY_DEFAULT,
} from '../../../utils/constants';
import config from '../../../config';
import * as actions from './actions';
import getLS from '../../../utils/getLS';

const initialSettingsState = {
  visible: false,
  values: {
    authToken: '',
    bucket: getLS('bucket') || get(config, `${process.env.API_ENV}.bucket`, ''),
    uploadName: '',
    uploadId: '',
    connections: getLS('connections', CONNECTIONS_DEFAULT),
    compression: getLS('compression', COMPRESSION_DEFAULT),
    fineTuningEnabled: getLS('fineTuningEnabled', 'false') === 'true',
    compressionEnabled: getLS('compressionEnabled', 'true') === 'true',
    simplifyTolerance: getLS('simplifyTolerance', SIMPLIFY_DEFAULT),
  },
};

const settingsReducer = (state, action) => {
  switch (action.type) {
    case actions.SHOW_SETTINGS:
      return {
        ...state,
        visible: true,
      };

    case actions.HIDE_SETTINGS:
      return {
        ...state,
        visible: false,
      };

    case actions.SET_SETTINGS_VALUE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.name]: get(action, 'payload.value', ''),
        },
      };

    case actions.FETCHING_UPLOAD_SUCCESS:
      return {
        ...state,
        values: {
          ...state.values,
          uploadName: get(action, 'payload.partialUpload.uploadName', ''),
          uploadId: get(action, 'payload.partialUpload.id', ''),
        },
      };

    case actions.LOCATION_CHANGE:
    case actions.CLEAR:
      return {
        ...state,
        values: {
          ...state.values,
          authToken: '',
          uploadName: '',
          uploadId: '',
        },
      };

    default:
      return state;
  }
};

export { initialSettingsState, settingsReducer };
