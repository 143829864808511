import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { v4 as uuid } from 'uuid';
import { getToken } from 'hummingbird-auth-lib/lib-esm/utils';
import config from '../config';

const authHandlerLink = new ApolloLink((operation, forward) => {
  const token = getToken();
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }));
  return forward(operation);
});

const setRequestIdLink = setContext((_, prevContext) => ({
  ...prevContext,
  headers: {
    ...prevContext?.headers,
    // a unique request ID for debugging: this is logged on the server for each request
    'X-Request-ID': uuid(),
  },
}));

const httpLink = new HttpLink({
  uri: `${config[process.env.API_ENV].gqlGatewayUrl}/graphql`,
});

export default new ApolloClient({
  link: ApolloLink.from([authHandlerLink, setRequestIdLink, httpLink]),
  cache: new InMemoryCache(),
  queryDeduplication: true,
  name: 'webuploader',
  version: process.env.APP_VERSION,
});
