import * as actions from './actions';
import { CODES } from '../../utils/language';
import { getLocaleData } from '../../utils/localStorage';

const initialLocaleState = getLocaleData() || CODES.EN_UK;

const localeReducer = (state, action) => {
  switch (action.type) {
    case actions.SET_LOCALE:
      return action.payload;
    default:
      return state;
  }
};

export { initialLocaleState, localeReducer };
