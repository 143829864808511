import * as Sentry from '@sentry/browser';
import { useEffect } from 'react';
import { getUser } from 'hummingbird-auth-lib/lib-esm/utils';

function SentryScope() {
  const user = getUser();

  useEffect(() => {
    if (user) {
      const { role, email, name, id } = user;
      Sentry.configureScope(scope => {
        scope.setUser({ email, username: name, id });
        scope.setTag('user_role', role);
      });
    }
  }, [user]);

  return null;
}

export default SentryScope;
