export const LOCALE_KEY = 'locale';
export const LANG_KEY = 'lang';

export const ROLES = {
  ADMIN: 'administrator',
  FARM_MANAGER: 'farm_manager',
  PILOT: 'agri_pilot',
  FARMER_PILOT: 'farmer_pilot',
  AGRONOMIST: 'agronomist',
};

export const UPLOAD_PERMISSIONS_STRING = 'uav.image.upload';

export const MAX_NON_IMAGE_FILES_SIZE = 104857600;

export const CONNECTIONS_MIN = 1;
export const CONNECTIONS_MAX = 20;
export const CONNECTIONS_DEFAULT = 4;

export const COMPRESSION_MIN = 1;
export const COMPRESSION_MAX = 9;
export const COMPRESSION_DEFAULT = 3;

export const SIMPLIFY_DEFAULT = 0.00000001;

export const FIND_FIELDS_BATCH_SIZE = 500;

export const YYYY_MM_DD_HH_MM = 'yyyy-MM-dd HH:mm';
export const YYYY_MM_DD = 'yyyy-MM-dd';
