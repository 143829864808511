import { LOCALE_KEY } from './constants';

export const saveLocaleData = locale => {
  localStorage.setItem(LOCALE_KEY, locale);
};

export const getLocaleData = () => {
  const token = localStorage.getItem(LOCALE_KEY);

  if (!token) {
    return null;
  }

  return token;
};
