/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes, { arrayOf, node } from 'prop-types';
import { Route } from 'react-router-dom';
import { LoginRedirect } from 'hummingbird-auth-lib/lib-esm/components';
import { useUser, withAuthData } from 'hummingbird-auth-lib/lib-esm/services';
import { hasTokenExpired } from 'hummingbird-auth-lib/lib-esm/utils';
import { hasUploadPermissions } from '../utils/checkPermissions';
import * as actions from './redux/actions';
import { useGlobalStore } from './redux/GlobalStore';

function PrivateRoute({ children, userId, lang, ...rest }) {
  const { dispatch } = useGlobalStore();
  const [user, fetchUser] = useUser();

  if (userId && user?.id !== userId) {
    fetchUser(userId);
  }

  if (lang) {
    dispatch({
      type: actions.SET_LOCALE,
      payload: lang,
    });
  }

  return (
    <Route
      {...rest}
      render={() =>
        !hasTokenExpired() ? (
          user && hasUploadPermissions(user) && children
        ) : (
          <LoginRedirect />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
  userId: PropTypes.string,
  lang: PropTypes.string,
};

PrivateRoute.defaultProps = {
  userId: null,
  lang: null,
};

export default withAuthData(PrivateRoute);
