/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import BackupIcon from '@material-ui/icons/Backup';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import Typography from './Typography';
import Button from './Button';

// TODO: Colors, etc to be defined in theme
const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme.palette.grey[400],
    borderStyle: 'dashed',
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[400],
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    minHeight: '86px',
  },
  icon: {
    width: 38,
    height: 38,
  },
  active: {
    borderColor: theme.palette.info.light,
  },
  accept: {
    borderColor: theme.palette.primary.main,
  },
  reject: {
    borderColor: theme.palette.error.light,
  },
  disabled: {
    color: theme.palette.grey[100],
    borderColor: theme.palette.grey[50],
    borderStyle: 'dashed',
    backgroundColor: theme.palette.common.white,
  },
}));

const directoryInputProps = {
  directory: 'true',
  webkitdirectory: 'true',
};

function Dropzone({ dropText, directory, disabled, accept, onDrop }) {
  const classes = useStyles();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const rootClasses = cx(classes.container, {
    [classes.active]: isDragActive,
    [classes.accept]: isDragAccept,
    [classes.reject]: isDragReject,
    [classes.disabled]: disabled,
  });
  const rootProps = getRootProps({ className: rootClasses });

  const inputProps = getInputProps({
    disabled,
    ...(directory ? directoryInputProps : {}),
  });

  return (
    <div data-testid="dropzone" {...rootProps}>
      <input
        data-testid="dropzoneinput"
        {...inputProps}
        accept={accept.toString()}
      />
      <BackupIcon
        color={disabled ? 'disabled' : 'primary'}
        className={classes.icon}
      />
      {isDragActive ? (
        <Typography
          variant="body1"
          testid="type-droptext"
          className={classes.type}
        >
          <FormattedMessage id="upload.dropTheFilesHere" />
        </Typography>
      ) : (
        <>
          <Typography variant="body1" testid="type-droptext">
            {dropText}{' '}
            <Button color="primary" variant="text" disabled={disabled}>
              <FormattedMessage id="upload.browseFiles" />
            </Button>
          </Typography>
        </>
      )}
    </div>
  );
}

Dropzone.propTypes = {
  dropText: PropTypes.string,
  directory: PropTypes.string,
  disabled: PropTypes.bool,
  accept: PropTypes.arrayOf(PropTypes.string),
  onDrop: PropTypes.func.isRequired,
};

Dropzone.defaultProps = {
  dropText: <FormattedMessage id="upload.dragAndDropAFolderHere" />,
  directory: 'false',
  disabled: false,
  accept: [],
};

export default Dropzone;
