import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import { useIntl, FormattedMessage } from 'react-intl';
import { Typography } from '../../../ui-components';
import HelpStep from './HelpStep';

function HelpContentNew() {
  const intl = useIntl();

  return (
    <>
      <HelpStep
        step="1"
        title={intl.formatMessage({ id: 'helpContent.createANewUpload' })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.toStartANewUploadClick" />
        </Typography>
      </HelpStep>

      <HelpStep
        step="2"
        title={intl.formatMessage({ id: 'helpContent.selectTheFiles' })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.dragAndDropOrClickInTheUpload" />
        </Typography>

        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.ifTheFolderContainsALargeNumberOf" />
        </Typography>
      </HelpStep>

      <HelpStep
        step="3"
        title={intl.formatMessage({ id: 'helpContent.fileProcessing' })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.afterSelectingTheFiles" />
        </Typography>
        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.thisProcessCanTakeSomeTime" />
        </Typography>
      </HelpStep>

      <HelpStep
        step="4"
        title={intl.formatMessage({ id: 'helpContent.fileProcessingErrors' })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.ifThereAreProblemsFound" />
        </Typography>

        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.uploadingWillBeDisabledIfThereAreErrors" />
        </Typography>
      </HelpStep>

      <HelpStep
        step="5"
        title={intl.formatMessage({
          id: 'helpContent.flightPathsAndFieldCoverage',
        })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.whenTheFilesHaveBeenProcessed" />
        </Typography>
      </HelpStep>

      <HelpStep
        step="6"
        title={intl.formatMessage({ id: 'helpContent.uploadName' })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.theUploadNameWillBeSetAutomatically"
            values={{ icon: <SettingsIcon fontSize="small" /> }}
          />
        </Typography>
      </HelpStep>

      <HelpStep
        step="7"
        title={intl.formatMessage({ id: 'helpContent.startTheUpload' })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.clickTheUploadFilesButtonToBegin" />
        </Typography>
      </HelpStep>

      <HelpStep
        step="8"
        title={intl.formatMessage({ id: 'helpContent.pauseAndResume' })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage id="helpContent.youMustKeepTheUploadPageOpen" />
        </Typography>
      </HelpStep>
    </>
  );
}
export default HelpContentNew;
